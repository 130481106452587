import { Button } from '@/components';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useStyles } from './styles';
import * as classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export const Menu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const styles = useStyles();
  const { asset_list } = useContext(AssetsContext) || {};
  const { faq_url, support_url } = asset_list || {};
  const [userEmail, setUserEmail] = useState(null);

  const navigate = useNavigate();

  const getUserEmail = useCallback(async () => {
    const user = await Auth.currentUserInfo();
    const email = user.attributes.email;

    setUserEmail(email);
  }, []);

  useEffect(() => {
    getUserEmail();
  }, []);

  return (
    <div
      className={classNames(styles.menu, { [styles.openMenu]: isMenuVisible })}
    >
      <div
        className={classNames(styles.burger, {
          [styles.openBurger]: isMenuVisible
        })}
        onClick={() => setIsMenuVisible(!isMenuVisible)}
      >
        <span />
      </div>
      {isMenuVisible && (
        <ul>
          {!!userEmail && (
            <li>
              <Button
                onClick={() => navigate('/about')}
                className={styles.button}
                text="About"
                size="large"
              />
            </li>
          )}
          <li>
            <Button
              onClick={() => navigate('/internetConnection')}
              className={styles.button}
              text="Internet connection"
              size="large"
            />
          </li>
          {faq_url && (
            <li>
              <Button
                onClick={() => window.open(faq_url)}
                className={styles.button}
                text="FAQ"
                size="large"
              />
            </li>
          )}
          {support_url && (
            <li>
              <Button
                onClick={() => window.open(support_url)}
                className={styles.button}
                text="Support"
                size="large"
              />
            </li>
          )}
          <li>
            <Button
              onClick={signOut}
              className={styles.button}
              text="Log out"
              size="large"
            />
          </li>
        </ul>
      )}
    </div>
  );
};
