import { getAppMeasurements, getScan, getScanImage, getUser } from '@/api';
import { ContentBlock, Typography, Button, Loader, Header } from '@/components';
import { AppContext, AssetsContext } from '@/GlobalProvider/GlobalProvider';
import {
  formatDateWithTime,
  getScanMeasurements,
  useIframeMessageHandler
} from '@/utils';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View
} from '@aws-amplify/ui-react';
import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from './styles';

export const Scan = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    behavior_list: { scan_review_option_panel },
    asset_list: { scan_review_custom_asset },
    imageUrl
  } = useContext(AssetsContext);
  const { id } = useContext(AppContext);
  const show_avatar = !!scan_review_option_panel;
  const styles = useStyles({ show_avatar });

  const [currentScan, setCurrentScan] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [measurements, setMeasurements] = useState([]);
  const [scanImage, setScanImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useIframeMessageHandler(currentCustomer);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [scan, customer, scanImage, scanMeasurements] = await Promise.all([
        getScan(params.scanId),
        getUser(params.customerId),
        getScanImage(params.scanId),
        getAppMeasurements(params.scanId)
      ]);
      setMeasurements(getScanMeasurements(id, scanMeasurements.output));
      setScanImage(scanImage.output.url);
      setCurrentCustomer(customer);
      setCurrentScan(scan);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!currentScan || !currentCustomer) {
    return null;
  }

  const userInfo = (
    <div>
      <Typography component="p">
        <span>Customer:</span> {currentCustomer.firstName}{' '}
        {currentCustomer.lastName}
      </Typography>
      <Typography component="p">
        <span>Date & time:</span> {formatDateWithTime(currentScan.date)}
      </Typography>
    </div>
  );

  return (
    <>
      <Header />
      <ContentBlock>
        <div className={styles.header}>
          <Typography component="h2" className={styles.title}>
            Scan History
          </Typography>
          {!show_avatar && userInfo}
        </div>
        <Grid
          templateColumns={show_avatar ? '1fr 1fr' : '1fr'}
          columnGap="1.5rem"
        >
          <View columnSpan={[2, 2, 1]}>
            {show_avatar && (
              <>
                <div className={styles.userInfo}>{userInfo}</div>
                {scan_review_option_panel === 'tailor_review' && (
                  <div className={styles.image}>
                    <img src={scanImage} />
                  </div>
                )}
                {scan_review_option_panel === 'custom_asset' &&
                  scan_review_custom_asset && (
                    <div className={styles.customImage}>
                      <img src={`${imageUrl}/${scan_review_custom_asset}`} />
                    </div>
                  )}
              </>
            )}
          </View>
          <View columnSpan={[2, 2, 1]}>
            <div className={styles.tableWrap}>
              <Table caption="" highlightOnHover={false} variation="striped">
                <TableHead>
                  <TableRow>
                    <TableCell as="th">Name</TableCell>
                    <TableCell as="th">Value</TableCell>
                    <TableCell as="th">Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {measurements.map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{value}</TableCell>
                      <TableCell>Inches</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </View>
        </Grid>
        <div className={styles.footer}>
          <Button
            buttonType="secondary"
            text="Back to Profile"
            className={styles.backButton}
            onClick={() => navigate(`/customers/${params.customerId}`)}
          />
        </div>
      </ContentBlock>
    </>
  );
};
