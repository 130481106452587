import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ContentBlock, Header, Loader, Typography } from '@/components';
import {
  CreateUpdateCustomerForm,
  UserCreateData
} from './CreateUpdateCustomerForm';
import { useStyles } from './styles';
import {
  addUserAgreements,
  createCustomer,
  createShare,
  getAgreements,
  getCustomerProvision,
  getUser,
  patchUser,
  provisionCustomer
} from '@/api';
import { AppContext, AlertContext } from '@/GlobalProvider/GlobalProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useIframeMessageHandler } from '@/utils';

export const CreateCustomer = () => {
  const styles = useStyles();
  const { id } = useContext(AppContext);
  const { setAlert } = useContext(AlertContext);
  const navigation = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const createNewUser = async (data: UserCreateData) => {
    try {
      if (data.email) {
        const newCustomerProvision = await getCustomerProvision(id, data.email);

        if (newCustomerProvision.id) {
          setAlert({
            text: 'A user with this email address already exists.',
            variation: 'error'
          });
          return;
        }
      }

      if (data.phone) {
        const newCustomerProvision = await getCustomerProvision(id, data.phone);

        if (newCustomerProvision.id) {
          setAlert({
            text: 'A user with this phone number already exists.',
            variation: 'error'
          });
          return;
        }
      }

      const response = await createCustomer(data, id);

      // create handle for phone if it exists
      if (data.phone) await provisionCustomer(response.id, id, data.phone);
      // create handle for email if it exists
      if (data.email) await provisionCustomer(response.id, id, data.email);

      await createShare(id, response.id);
      const agreementsResponse = await getAgreements(id);
      await addUserAgreements(
        response.id,
        agreementsResponse,
        `${response.firstName} ${response.lastname}`
      );

      navigation(`/customers/${response.id}`);
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'User with this data was not found!',
          variation: 'error'
        });
      }
    }
  };

  const updateUser = async (value: UserCreateData) => {
    try {
      if (value.email && !currentCustomer.email) {
        const newCustomerProvision = await getCustomerProvision(
          id,
          value.email
        );

        if (newCustomerProvision.id) {
          setAlert({
            text: 'A user with this email address already exists.',
            variation: 'error'
          });
          return;
        }
      }

      if (value.phone && !currentCustomer.phone) {
        const newCustomerProvision = await getCustomerProvision(
          id,
          value.phone
        );

        if (newCustomerProvision.id) {
          setAlert({
            text: 'A user with this phone number already exists.',
            variation: 'error'
          });
          return;
        }
      }

      // create handle for phone if it exists
      if (value.phone && !currentCustomer.phone)
        await provisionCustomer(currentCustomer.id, id, value.phone);
      // create handle for email if it exists
      if (value.email && !currentCustomer.email)
        await provisionCustomer(currentCustomer.id, id, value.email);

      if (currentCustomer) {
        await patchUser({ ...value, id: currentCustomer.id });
        navigation(`/customers/${currentCustomer.id}`);
      }
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'User with this data was not found!',
          variation: 'error'
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (params.customerId) {
        const customerData = await getUser(params.customerId);
        if (!customerData.status) {
          setCurrentCustomer(customerData);
        }
      }
      setLoading(false);
    })();
  }, []);

  useIframeMessageHandler(currentCustomer);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <ContentBlock
        hasBackButton
        backHandler={
          !!currentCustomer &&
          (() => navigation(`/customers/${params.customerId}`))
        }
      >
        <div className={styles.header}>
          <Typography component="h3">Hello!</Typography>
          <Typography component="h2">
            {!!currentCustomer ? 'Update customer' : 'Sign up to get started '}
          </Typography>
        </div>
        <CreateUpdateCustomerForm
          submitHandler={currentCustomer ? updateUser : createNewUser}
          defaultValue={currentCustomer}
          isUpdateForm={!!currentCustomer}
        />
      </ContentBlock>
    </>
  );
};
