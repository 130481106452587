import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  customer: {
    '@media (max-width: 768px)': {
      padding: '0 30px'
    }
  },
  leftBlock: {
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  qrCodeBlock: {
    paddingLeft: 20,
    marginBottom: 119,
    '& canvas': {
      width: '320px!important',
      height: '320px !important'
    }
  },
  previousScan: {
    '@media (max-width: 768px)': {
      marginBottom: 20
    }
  },
  qrCodeBlockMobile: {
    marginBottom: 70,
    display: 'none',
    '& canvas': {
      width: '100%!important',
      height: '100% !important',
      maxWidth: '400px',
      maxHeight: '400px'
    },
    '@media (max-width: 768px)': {
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center'
    }
  },
  backButton: {
    width: '100%',
    maxWidth: 250,
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: 15,
      '& > button': {
        maxWidth: 260
      }
    }
  },
  br: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'block'
    }
  },
  content: {
    overflow: 'hidden'
  },
  title: {
    marginBottom: 30,
    '@media (max-width: 768px)': {
      textAlign: 'center',
      width: '100%'
    }
  },
  subtitle: {
    paddingBottom: 10
  },
  personalInfo: {
    paddingBottom: 20
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  },
  button: {
    maxWidth: 200,
    marginBottom: 15,
    '@media (max-width: 768px)': {
      maxWidth: '100%'
    }
  },
  scanBlockTitle: {
    marginBottom: 20
  },
  scan: {
    borderRadius: 15,
    border: `1px solid ${theme.colors.black}`,
    padding: '10px 10px',
    '@media (max-width: 768px)': {
      marginBottom: 20
    }
  },
  scanDetails: {
    padding: '5px 0'
  },
  continueShoppingButton: {
    textAlign: 'right',
    width: '100%',
    maxWidth: 250,
    '& > button': {
      width: '100%'
    },
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      textAlign: 'center',
      '& > button': {
        maxWidth: 260
      }
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 30,
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  },
  refresh: {
    maxWidth: 200,
    marginBottom: 10,
    '@media (max-width: 768px)': {
      maxWidth: '100%'
    }
  }
}));
