import * as Yup from 'yup';
import { EMAIL_REGEX } from '@/regex';
import parsePhoneNumber from 'libphonenumber-js';

export const getSchema = (
  { countryCode, dialCode },
  errorMessage,
  isEmailRequired,
  isPhoneRequired,
  isPhoneOptional
) =>
  Yup.object().shape(
    {
      email: Yup.string()
        .when('phone', {
          is: (phone) => !phone || isEmailRequired,
          then: Yup.string().required(errorMessage),
          otherwise: Yup.string()
        })
        .matches(EMAIL_REGEX, 'Enter valid email address'),
      ...(isPhoneRequired
        ? {
            phone: Yup.string()
              .required(errorMessage)
              .test('isCorrectNumber', `Phone number isn't valid`, (val) => {
                if (!countryCode || dialCode === val) return true;

                const phone = parsePhoneNumber(val, countryCode.toUpperCase());

                return phone?.isValid();
              })
          }
        : {
            phone: Yup.string().when('email', {
              is: (email) => !!email || isPhoneOptional,
              then: Yup.string().test(
                'isCorrectNumber',
                `Phone number isn't valid`,
                (val) => {
                  if (!countryCode || dialCode === val) return true;

                  const phone = parsePhoneNumber(
                    val,
                    countryCode.toUpperCase()
                  );

                  return phone?.isValid();
                }
              ),
              otherwise: Yup.string()
                .required(errorMessage)
                .test('isRequiredWithCountryCode', errorMessage, (val) => {
                  return dialCode !== val;
                })
                .test('isCorrectNumber', `Phone number isn't valid`, (val) => {
                  if (!countryCode) return true;

                  const phone = parsePhoneNumber(
                    val,
                    countryCode.toUpperCase()
                  );

                  return phone?.isValid();
                })
            })
          }),
      firstName: Yup.string()
        .required('First name is required')
        .max(35, 'Max length for first name is 35 characters'),
      lastName: Yup.string()
        .required('Last name is required')
        .max(35, 'Max length for last name is 35 characters'),
      dateOfBirth: Yup.number()
        .nullable()
        .required('Age is required')
        .min(18, 'You must enter an age 18 or older')
        .max(120, 'Max age 120 years'),
      feet: Yup.number().when('heightMeasure', {
        is: (heightMeasure) => heightMeasure === 'fnIn',
        then: Yup.number()
          .nullable()
          .required('Feet is required')
          .min(5, 'Min feet value is 5')
          .max(7, 'Max feet value is 7'),
        otherwise: Yup.number().nullable()
      }),
      inch: Yup.number().when('heightMeasure', {
        is: (heightMeasure) => heightMeasure === 'fnIn',
        then: Yup.number()
          .nullable()
          .required('Inch is required')
          .min(0, 'Min inch value is 0')
          .max(11, 'Max inch value is 11'),
        otherwise: Yup.number().nullable()
      }),
      height: Yup.number().when('heightMeasure', {
        is: (heightMeasure) => heightMeasure === 'cm',
        then: Yup.number()
          .nullable()
          .required('Height is required')
          .min(100, 'Height must be greater than or equal to 100 см')
          .max(250, 'Height must be less than or equal to 250 см'),
        otherwise: Yup.number().nullable()
      }),
      weight: Yup.number()
        .nullable()
        .required('Weight is required')
        .when('weightMeasure', {
          is: (weightMeasure) => weightMeasure === 'kgs',
          then: Yup.number()
            .nullable()
            .min(30, 'Weight must be greater than or equal to 30 kgs')
            .max(200, 'Weight must be less than or equal to 200 kgs'),
          otherwise: Yup.number()
            .nullable()
            .min(66, 'Weight must be greater than or equal to 66 lbs')
            .max(440, 'Weight must be less than or equal to 440 lbs')
        }),
      termsAndConditions: Yup.bool().oneOf([true])
    },
    [
      ['phone', 'email'],
      ['email', 'phone'],
      ['height', 'heightMeasure'],
      ['weight', 'weightMeasure']
    ]
  );
