const oneCmInFt = 0.032808399;
export const onePnInKg = 2.2046226218;
const oneCmInInch = 0.393701;
const inchesInFt = 12;

export const covertCmToFt = (value: number): [number, number] => {
  if (value === null) return [null, null];

  if (value < 153) {
    return [5, 0];
  } else if (value > 241) {
    return [7, 11];
  }
  const ftValue = Math.floor(value * oneCmInFt);
  const inchesValue = Math.floor((value - ftValue / oneCmInFt) * oneCmInInch);
  return [ftValue, inchesValue];
};

export const convetFtToCm = (
  feet: number | null,
  inch: number | null
): number => {
  if (feet === null && inch === null) return null;

  return Math.round(feet / oneCmInFt + inch / oneCmInInch);
};

export const converFtToInches = (feet: number, inch: number): number => {
  if (feet === null && inch === null) return null;

  return feet * inchesInFt + Number(inch);
};

export const convertInchesToFt = (
  value: number | null
): { feet: number; inch: number } => {
  if (value === null) return { feet: null, inch: null };
  return { feet: ~~(value / inchesInFt), inch: value % 12 };
};
