export const formatDateWithTime = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  let hours = d.getHours();
  let minutes: number | string = d.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;

  return `${[year, month, day].join('-')} ${strTime}`;
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const convertAgeToDate = (age: number) => {
  const ageInMilliseconds = age * 1000 * 60 * 60 * 24 * 365;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const dateOfBirthInMilliseconds = new Date() - new Date(ageInMilliseconds);
  return formatDate(dateOfBirthInMilliseconds);
};

export const convertDateToAge = (date: string | number) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const ageInMilliseconds = new Date() - new Date(date);
  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
};
