import * as React from 'react';
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { getApps, getAssetsFile, getDefaultAssetsFile, getUserIP } from '@/api';
import { createUseStyles, ThemeProvider } from 'react-jss';
import { theme } from '@/theme';
import { Alert, Loader } from '@/components';
import { AlertProps } from '@aws-amplify/ui-react';
import SpeedTest from '@cloudflare/speedtest';
import { inIframe } from '@/utils';

export const AppContext = React.createContext(null);
export const AssetsContext = React.createContext(null);
export const AlertContext = React.createContext(null);
export const InternetConnectionContext = React.createContext(null);
export const InIframeContext = React.createContext(false);

const defaultAssetsUrl = process.env.REACT_APP__DEFAULT_ASSETS_URL;
const assetsUrl = process.env.REACT_APP_ASSETS_URL;
const assetsFileVersion = process.env.REACT_APP_ASSETS_FILE_VERSION;

const useStyles = createUseStyles({
  wrapper: {
    height: '100vh',
    width: '100vw',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

type Measurements = ReturnType<
  Parameters<SpeedTest['onFinish']>[number]['getSummary']
>;

export const GlobalProvider = ({ children }) => {
  const [appData, setAppData] = useState(null);
  const [assetsDate, setAssetsDate] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [alert, setAlert] = useState<
    (AlertProps & { text: React.ReactElement }) | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [measurements, setMeasurements] = useState<null | Measurements>(null);
  const [IP, setIP] = useState('');

  const isInIframe = inIframe();

  const styles = useStyles();
  const engine = useRef(null);

  const refresh = useCallback(() => {
    if (engine.current) {
      setMeasurements(null);
      engine.current.restart();
    } else {
      engine.current = new SpeedTest();
      engine.current.onFinish = (results) => {
        setMeasurements(results.getSummary());
      };
    }
  }, []);

  const appContextValue = useMemo(() => {
    if (!appData) return null;

    return { ...appData[0] };
  }, [appData]);

  const assetsContextValue = useMemo(
    () => ({ ...assetsDate, imageUrl }),
    [assetsDate, imageUrl]
  );
  const alertContextValue = useMemo(() => ({ setAlert }), [setAlert]);
  const internetConnectionContextValue = useMemo(
    () => ({ refresh, measurements, IP }),
    [refresh, measurements, IP]
  );

  useEffect(() => {
    const getAppData = async () => {
      setLoading(true);
      const IP = await getUserIP();
      setIP(IP);
      const response = await getApps();
      const res = await getAssetsFile(response[0].id);
      if (res && res.asset_file_version === assetsFileVersion) {
        setImageUrl(`${assetsUrl}/${response[0].id}`);
        setAssetsDate(res);
      } else {
        const defaultRes = await getDefaultAssetsFile();
        if (defaultRes.asset_file_version === assetsFileVersion) {
          setImageUrl(defaultAssetsUrl);
          setAssetsDate(defaultRes);
        }
      }
      setAppData(response);
      setLoading(false);
    };
    getAppData();
  }, []);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  if (loading)
    return (
      <ThemeProvider theme={theme}>
        <div className={styles.wrapper}>
          <Loader />
        </div>
      </ThemeProvider>
    );

  if (!appData) return null;

  return (
    <AppContext.Provider value={appContextValue}>
      <AssetsContext.Provider value={assetsContextValue}>
        <AlertContext.Provider value={alertContextValue}>
          <InternetConnectionContext.Provider
            value={internetConnectionContextValue}
          >
            <InIframeContext.Provider value={isInIframe}>
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
              {alert && (
                <Alert {...alert} onDismiss={() => setAlert(null)}>
                  {alert.text}
                </Alert>
              )}
            </InIframeContext.Provider>
          </InternetConnectionContext.Provider>
        </AlertContext.Provider>
      </AssetsContext.Provider>
    </AppContext.Provider>
  );
};
